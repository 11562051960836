a,
br,
b,
b {
  border: 0;
  box-sizing: border-box;
  font-size: 100%;
  font-style: inherit;
  font-weight: inherit;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
}

.tableOuter {
  width: 100%;
  border-collapse: collapse;
  /* border-spacing: 0; */
  /* border: 1px solid #bfbfbf; */
  margin-bottom: 20px;
}

.scheduleTableContainer {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  margin: 0 auto;
}

.tableRow,
.tableOuter,
.tableColumn {
  border: 1px #bfbfbf inset;
}

table {
  font-size: 100%;
  font-style: inherit;
  font-weight: inherit;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
  border-collapse: collapse;
  color: #454545;
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  font-weight: 400;
}

b {
  font-weight: 700;
}

.tableColumn {
  padding: 5px;
  vertical-align: top;
}

th {
  font-size: 11px;
  font-weight: 700;
  text-align: left;
  /* padding: 10px 5px !important; */
}

.masseuseName {
  font-size: 20px;
  font-weight: bold;
  color: #c3892b;
  margin-top: 10px;
  margin-bottom: -5px;
}

.image-blur {
  filter: blur(4px);
}

.masseuse-image-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-color-black {
  color: black;
}

.masseuse-name-small:hover {
  text-decoration: underline;
}

.table-body-wrapper > div {
  display: table-row-group;
}

.table-body-wrapper > div > div {
  display: table-row;
}
