/* .carousel {
  width: 100%;
  height: 380px;
  margin: auto;
}*/

/* .carousel-control-prev {
  height: 300px;
}
.carousel-control-next {
  height: 300px;
} */

/* Set the width of the controls to auto to shrink the clickable area */
.carousel-control-prev,
.carousel-control-next {
  height: 40px;
  width: auto; /* Adjust this to make the clickable area smaller */
}

/* Continue using your background styling */
.carousel .carousel-control-prev-icon,
.carousel .carousel-control-next-icon {
  background-color: #000; /* Existing style for background color */
  width: 40px; /* Define width for the icon itself */
  height: 40px; /* Define height for the icon itself */
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Position adjustments for the icon containers */
.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

/* Specific positioning for the left and right icons */
.carousel-control-prev {
  left: 10px; /* Adjust as needed for left icon placement */
}

.carousel-control-next {
  right: 10px; /* Adjust as needed for right icon placement */
}

/* Handling hover state for visibility */
.carousel:hover .carousel-control-prev-icon,
.carousel:hover .carousel-control-next-icon {
  visibility: visible;
}
a {
  text-decoration: none;
  color: #c3892b;
}
a:link,
a:visited,
a:hover,
a:active {
  text-decoration: none;
}
